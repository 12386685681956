import type { NextPage } from 'next';
import React from 'react';
import Header from '../src/Components/molecules/Header/Header';
import List from '../src/Components/molecules/List/List';
import Sidebar from '../src/Components/molecules/Sidebar/Sidebar';
import Footer from '../src/Components/molecules/Footer/Footer';
import { getPostList as IgetPostList } from '../src/lib/api/types';
import HeadCustom from '../src/Components/molecules/Head/Head';
import usePostGet from '../src/hooks/usePostGet';
import NewsletterCTAShort from '../src/Components/molecules/NewsletterCTA/NewsletterCTAShort';

const Home: NextPage<IgetPostList> = () => {
    const { list } = usePostGet();

    return (
        <>
            <HeadCustom />
            <Header />

            <section className="relative flex w-full items-center justify-center lg:hidden">
                <NewsletterCTAShort />
            </section>

            <div className="relative m-auto w-screen max-w-screen-xl p-0 sm:flex sm:flex-col md:p-3 xl:grid xl:grid-cols-12 xl:gap-5 xl:p-0 ">
                <div className="col-span-8">
                    <List list={list ?? null} />
                </div>
                <div className="relative col-span-4">
                    <Sidebar />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Home;
