import Link from 'next/link';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPen, faSync } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import useSubscribe from './hooks/useSubscribe';
import subscribeRecoil from './recoil/subscribeRecoil';

const NewsletterCTAShort: React.FC = () => {
    const [subscribeState, setSubscribeState] = useRecoilState(subscribeRecoil);

    const [focused, setFocused] = useState(false);

    const { inProgress, showError, isSent, checkMail } = useSubscribe();

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-3xl flex-col text-base">
                <div className="relative z-10 mx-auto w-full flex-col lg:flex lg:items-center">
                    {isSent && (
                        <div className="relative flex bg-green-200 p-4 font-serif text-xs text-green-900">
                            Please confirm your email address in your e-mail inbox.
                        </div>
                    )}

                    {!isSent && (
                        <div className="mx-auto flex w-full justify-center px-2">
                            {/* Grid */}
                            <div className="mt-2 grid w-full max-w-md grid-cols-[auto,40px] pl-2">
                                <div className="flex flex-row items-center justify-start gap-0">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="absolute text-neutral-800/20"
                                    />
                                    <input
                                        id="email-address"
                                        name="email-address"
                                        type="email"
                                        autoComplete="email"
                                        onFocus={() => {
                                            setFocused(true);
                                        }}
                                        onBlur={() => {
                                            setFocused(false);
                                        }}
                                        onKeyPress={async (event) => {
                                            if (event.key === 'Enter') {
                                                if (await checkMail(subscribeState.email)) {
                                                    setSubscribeState({
                                                        ...subscribeState,
                                                        showModal: true,
                                                    });
                                                }
                                            }
                                        }}
                                        required
                                        className={
                                            showError ? 'icon error form-input' : 'icon form-input'
                                        }
                                        placeholder="Daily CTO Newsletter"
                                        onChange={(e) =>
                                            setSubscribeState({
                                                ...subscribeState,
                                                email: e.target.value,
                                            })
                                        }
                                        value={subscribeState.email}
                                    />
                                </div>

                                <div className="mt-3 flex w-full items-center justify-center">
                                    <button
                                        disabled={inProgress}
                                        onClick={async () => {
                                            if (await checkMail(subscribeState.email)) {
                                                setSubscribeState({
                                                    ...subscribeState,
                                                    showModal: true,
                                                });
                                            }
                                        }}
                                        type="button"
                                        className="relative left-1 flex h-8 w-8 items-center justify-center rounded-full bg-neutral-300 py-2 px-2 text-base font-medium text-white"
                                    >
                                        {!inProgress && (
                                            <>
                                                <FontAwesomeIcon className="" icon={faPen} />
                                            </>
                                        )}
                                        {inProgress && (
                                            <>
                                                <FontAwesomeIcon
                                                    className="animate-spin"
                                                    icon={faSync}
                                                />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {focused && (
                        <p className="m-auto mt-3 max-w-7xl max-w-md px-2 text-sm text-xs text-gray-500 opacity-70">
                            This is how we handle your data{' '}
                            <Link href="/page/privacy" passHref>
                                <span className="cursor-pointer font-medium text-primary">
                                    Privacy policy
                                </span>
                            </Link>
                            .{` `}
                            <span>
                                We use rapidmail to send our newsletter. With your registration you
                                agree that the entered data will be transmitted to data will be
                                transmitted to rapidmail. Please note their{' '}
                                <Link href="https://www.rapidmail.de/agb" passHref>
                                    <a
                                        href="https://www.rapidmail.de/agb"
                                        className="text-primary"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        General Terms of Business
                                    </a>
                                </Link>{' '}
                                and{' '}
                                <Link
                                    href="https://www.rapidmail.de/datenschutz-kundenbereich"
                                    passHref
                                >
                                    <a
                                        href="https://www.rapidmail.de/datenschutz-kundenbereich"
                                        className="text-primary"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy policy
                                    </a>
                                </Link>
                                .
                            </span>
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewsletterCTAShort;
