import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons';
import PostTag from '../Post/PostTag';
import PostTagSmall from '../Post/PostTagSmall';

export interface IPostData {
    title: string;
    href: string;
    isSticky?: boolean;
    category: {
        name: string;
        href: string;
    };
    tags: string[];
    description: string;
    date: string;
    imageUrl: string;
    imageDescription: string;
    readingTime?: number;
    author: {
        name: string;
        href: string;
        imageUrl: string;
        description: string;
    };
    commentCount?: number | null;
    excerpt: string;
    content?: string;
    mediumHref?: string;
    linkedInHref?: string;
}

export interface IListPost {
    post: IPostData;
}

const ListPost: React.FC<IListPost> = ({ post }) => (
    <>
        <div
            key={post.title}
            className="relative mb-5 flex h-full flex-col overflow-hidden rounded-none shadow-lg md:rounded-md"
        >
            <div className="relative col-span-6 flex h-full w-full flex-col">
                <div className="aspect-w-16 aspect-h-9 relative flex h-full w-full">
                    <Image
                        src={post.imageUrl !== '' ? post.imageUrl : '/images/asbg_flat.jpg'}
                        layout="fill"
                        objectFit="cover"
                        alt={post.title ?? ''}
                        quality="80"
                        loading="lazy"
                        placeholder="blur"
                        blurDataURL="images/asbg_flat.jpg"
                    />
                </div>

                {post.isSticky && (
                    <div className="absolute top-2 left-1 z-10">
                        <PostTag label="Featured" primary showLogo />
                    </div>
                )}
                {/* Tagrow */}
                <div className="absolute -bottom-2 right-1 flex w-full flex-row flex-wrap items-end justify-end pb-3">
                    {post?.tags?.map((tag) => (
                        <PostTagSmall label={tag ?? ''} primary={false} key={nanoid()} />
                    ))}
                </div>
            </div>

            {/* Description */}

            {post.imageDescription !== '' && (
                <div className="w-full bg-white pt-1 pr-1 text-right text-xs font-thin text-neutral-500">
                    {post.imageDescription ?? ''}
                </div>
            )}
            <div className="col-span-6 flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                    <p className="font-serif text-sm font-semibold uppercase tracking-wider text-primary">
                        <Link href={post.category.href} passHref>
                            <span className="hover:underline">{post.category.name}</span>
                        </Link>
                    </p>
                    {/* Postlink */}
                    <Link href={post.href} passHref>
                        <a href={post.href} className="mt-2 block cursor-pointer">
                            <p className="transform font-serif text-xl text-secondary transition-all hover:text-primary ">
                                {post.title}
                            </p>
                            <p
                                className="mt-3 text-base text-gray-500"
                                dangerouslySetInnerHTML={{ __html: post.description }}
                            />
                        </a>
                    </Link>
                </div>

                {/* Post Footer */}
                <div className="mt-6 flex w-full flex-col items-start gap-2 md:flex-row">
                    <div className="flex flex-col">
                        {/* Footerline */}
                        <p className="text-sm font-medium text-gray-900">
                            <Link href={post.author.href} passHref>
                                <span className="hover:underline">{post.author.name}</span>
                            </Link>
                        </p>
                        <div className="flex flex-row flex-wrap space-x-1 text-sm text-gray-500">
                            <span>{dayjs(post.date).format('DD/MM/YYYY')}</span>
                            <div
                                className="hidden px-1 text-secondary sm:inline-block"
                                aria-hidden="true"
                            >
                                &#47;
                            </div>
                            <span>
                                <FontAwesomeIcon icon={faClock} className="mr-1 text-primary" />
                                {post.readingTime} Minute
                                {Number(post.readingTime) > 1 ? 'n' : ''} reading time
                            </span>
                        </div>
                    </div>

                    {/* Links */}
                    <section className="relative flex w-full flex-row justify-start gap-4 md:justify-end">
                        {post.mediumHref && post.mediumHref !== '' && (
                            <Link passHref href={post.mediumHref}>
                                <a target="medium" href={post.mediumHref}>
                                    <FontAwesomeIcon
                                        icon={faMedium}
                                        className="text-2xl text-black md:text-4xl"
                                    />
                                </a>
                            </Link>
                        )}
                        {post.linkedInHref && post.linkedInHref !== '' && (
                            <Link passHref href={post.linkedInHref}>
                                <a target="linkedin" href={post.linkedInHref}>
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        className="text-2xl text-linkedin md:text-4xl"
                                    />
                                </a>
                            </Link>
                        )}
                    </section>
                </div>
            </div>
        </div>
    </>
);

export default ListPost;
