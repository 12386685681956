import React, { FunctionComponent } from 'react';
import { nanoid } from 'nanoid';
import ListPost, { IPostData } from '../ListPost/ListPost';

interface IList {
    list: IPostData[];
}

const List: FunctionComponent<IList> = (props) => {
    const { list } = props;

    return (
        <>
            <div className="mt-2 block lg:mt-12" />
            <div className="relative mb-5 flex w-full">
                {list &&
                    list.map((item) => {
                        if (item.isSticky) return <ListPost post={item} key={nanoid()} />;
                        return <div className="hidden" key={nanoid()} />;
                    })}
            </div>
            <div className="mx-auto flex max-w-screen-xl flex-col gap-5">
                {list &&
                    list.map((item) => {
                        if (!item.isSticky) return <ListPost post={item} key={nanoid()} />;
                        return <div className="hidden" key={nanoid()} />;
                    })}
            </div>
        </>
    );
};

export default List;
