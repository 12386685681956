import React from 'react';

interface IPostTagline {
    label: string;
    primary: boolean;
}

const PostTagSmall: React.FC<IPostTagline> = ({ label = '', primary = false }) => (
    <>
        <div
            className={`inline-flex uppercase font-serif items-baseline h-6 px-3 mb-2 py-1 rounded-full text-xs md:mt-2 lg:mt-0 mx-1 tracking-wide whitespace-nowrap ${
                primary ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-800'
            } `}
        >
            {label}
        </div>
    </>
);

export default PostTagSmall;
